<template>
  <div>
    <!-- Submission Card Header -->
    <b-card
      bg-variant="transparent"
      class="material-card border-light-secondary mb-1"
    >
      <div class="d-flex">
        <div class="mr-1">
          <b-avatar
            rounded="lg"
            :variant="taskType(tasks.content_type).variant"
          >
            <i class="card-icon " :class="taskType(tasks.content_type).icon" />
          </b-avatar>
        </div>
        <div class="d-flex flex-column">
          <div class="d-flex justify-content-between">
            <div class="d-flex flex-column ">
              <span class="text-muted">
                {{ taskType(tasks.content_type).title }}
              </span>
              <h6 class="font-weight-bolder small-gap-bottom">
                Ujian : {{ tasks.name }}
              </h6>
            </div>
          </div>

          <b-card-text>
            {{ tasks.description }}
          </b-card-text>
        </div>
      </div>
    </b-card>

    <div v-if="submissionId !== ''">
      <!-- Form Submission -->
      <div>
        <b-card>
          <!-- Download Deskripsi Tugas (File) if exist -->
          <div
            v-if="tasks.submission_questions[0].attachment !== null"
            class="small-gap-bottom"
          >
            <label class="d-block small-gap-bottom">
              Deskripsi Ujian (File)
            </label>
            <b-button
              variant="primary"
              :href="tasks.submission_questions[0].attachment"
              target="_blank"
            >
              <i
                class="regular-icon align-middle ti ti-download small-gap-right"
              />
              <span class="align-middle">Download Deskripsi Ujian</span>
            </b-button>
          </div>

          <!-- URL Input -->
          <b-form-group v-if="submissionType === 'url'" class="mb-1">
            <label class="d-block">Link Upload</label>
            <b-form-input
              v-model="formData.uploadLink"
              placeholder="Masukan Link Video : eg. https://www.youtube.com/watch?v=1"
              :state="formErrors.uploadLink.state"
            />
            <b-form-invalid-feedback v-show="!formErrors.uploadLink.state">
              {{ formErrors.uploadLink.message }}
            </b-form-invalid-feedback>
          </b-form-group>

          <!-- Text Input -->
          <b-form-group v-if="submissionType === 'text'" class="mb-1">
            <label class="d-block">Text</label>
            <quill-editor v-model="formData.text" :options="snowOption" />
            <b-form-invalid-feedback v-show="!formErrors.text.state">
              {{ formErrors.text.message }}
            </b-form-invalid-feedback>
          </b-form-group>

          <!-- File Input -->
          <div v-if="submissionType === 'file'">
            <label class="d-block">Upload File</label>
            <div v-if="filelist.length === 0" class="drop-file-con">
              <b-card
                class="drop-file p-4 mt-2"
                @dragover="dragover"
                @dragleave="dragleave"
                @drop="drop"
              >
                <div class="d-flex flex-column align-items-center">
                  <b-avatar size="lg" rounded="sm" variant="light-primary">
                    <i class="card-icon-lg ti ti-file-upload"></i>
                  </b-avatar>
                  <h3 class="pt-1 small-gap-bottom">
                    Seret file di sini
                  </h3>
                  <span class="small-gap-bottom">
                    (Format file yang didukung: .pdf, .doc, .docx, .xls, .xlsx,
                    .ppt, .pptx)
                  </span>
                  <b-button variant="primary" @click="$refs.file.click()">
                    Upload File</b-button
                  >
                </div>
              </b-card>
            </div>

            <!-- Display Selected File -->
            <b-row v-show="filelist.length != 0">
              <b-col lg="2">
                <b-card
                  class="border-light-secondary d-flex flex-column text-center"
                  v-for="(file, index) in filelist"
                  :key="index"
                >
                  <b-button
                    class="remove-button-x"
                    variant="danger"
                    size="sm"
                    @click="remove(index)"
                  >
                    <i class="ti ti-x"></i>
                  </b-button>
                  <b-avatar
                    rounded
                    :variant="fileType(getExtension(file.name))"
                  >
                    <span class="p-2"> {{ getExtension(file.name) }}</span>
                  </b-avatar>
                  <div class="mt-1">
                    <span>
                      {{ ellipsis(file.name) }}
                    </span>
                  </div>
                </b-card>

                <!-- Hidden Input -->
                <input
                  ref="file"
                  hidden
                  type="file"
                  accept=".pdf,.doc, .docx,.ppt, .pptx,.xls, .xlsx"
                  @change="onChange()"
                />
              </b-col>
            </b-row>
          </div>
        </b-card>

        <!-- Save Button -->
        <div class="text-right mb-2">
          <b-button variant="primary" @click="submitForm">
            <b-spinner
              v-show="isButtonLoading"
              class="mr-1"
              variant="light"
              small
            />
            <i
              v-show="!isButtonLoading"
              class="regular-icon align-middle ti ti-checklist small-gap-right"
            />
            <span class="align-middle">Kumpulkan Ujian</span>
          </b-button>
        </div>
      </div>
    </div>

    <!-- Loading -->
    <b-overlay
      class="d-flex align-items-center flex-column mt-4"
      v-if="submissionId === ''"
    >
      <b-spinner variant="primary"></b-spinner>
      <span class="small-gap-top">Memuat Form</span>
    </b-overlay>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BCardText,
  BFormInput,
  BAvatar,
  BFormGroup,
  BFormInvalidFeedback,
  BSpinner,
  BOverlay,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'

import { avatarText, fileType, taskType } from '@core/utils/filter'
import client from '@/libs/http/axios-config'
import { checkAuthorizeRole } from '@/auth/utils'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BCardText,
    BFormInput,
    BAvatar,
    BFormGroup,
    BFormInvalidFeedback,
    BSpinner,
    BOverlay,

    quillEditor,
  },
  props: {
    tasks: {
      type: Object,
      default: () => {},
    },
    submissionType: {
      type: String,
      default: 'text',
    },
  },
  data() {
    return {
      // State
      isLoading: true,
      isButtonLoading: false,

      // Default data
      classroomId: this.$router.currentRoute.params.id,
      contentId: this.$router.currentRoute.params.contentId,
      taskId: this.$router.currentRoute.params.taskId,
      submissionId: '',

      // FormData
      filelist: [],
      formErrors: {
        uploadLink: { state: null, message: '' },
        text: { state: null, message: '' },
      },
      formData: {
        uploadLink: '',
        text: '',
      },
    }
  },
  async mounted() {
    await client
      .get(
        `/students/classrooms/${this.classroomId}/class-contents/${this.contentId}/tasks/${this.taskId}`
      )
      .then((response) => {
        this.submissionId =
          response.data.data.class_content.submission_questions[0].id
      })
      .catch((error) => {
        console.log('Submission: ', error)
      })
  },
  methods: {
    // Filter / Misc
    avatarText,
    fileType,
    taskType,
    getExtension(filename) {
      return filename.split('.').pop()
    },
    showToast(variant, icon, title, message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text: message,
          variant,
        },
      })
    },

    // File Uploader
    ellipsis(text) {
      if (text.length > 6) {
        return `${text.substring(0, 10)}...${text.substr(
          text.lastIndexOf('.') + 1
        )}`
      }
      return text
    },
    onChange() {
      this.filelist = [...this.$refs.file.files]
    },
    remove(i) {
      this.filelist.splice(i, 1)
      const allDropFileContainer = document.querySelectorAll('.drop-file')

      allDropFileContainer.forEach((dropFile) => {
        dropFile.classList.remove('bg-drop-file')
      })
    },
    dragover(event) {
      event.preventDefault()
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains('bg-drop-file')) {
        event.currentTarget.classList.add('bg-drop-file')
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.remove('bg-drop-file')
    },
    drop(event) {
      event.preventDefault()
      const droppedFiles = event.dataTransfer.files

      if (droppedFiles.length > 1) {
        event.currentTarget.classList.remove('bg-drop-file')
        this.showToast(
          'danger',
          'XIcon',
          'Gagal',
          'File tidak boleh lebih dari satu'
        )
      } else {
        droppedFiles.forEach((element) => {
          const fileExtension = element.name.split('.').pop()
          const allowedExtension = [
            'pdf',
            'doc',
            'docx',
            'ppt',
            'pptx',
            'xls',
            'xlsx',
            'mp4',
            'mkv',
          ]

          if (allowedExtension.includes(fileExtension)) {
            this.filelist.push(element)
          } else {
            event.currentTarget.classList.remove('bg-drop-file')
            this.showToast('danger', 'XIcon', 'Gagal', 'File tidak didukung')
          }
        })
      }
    },

    // Form Submission
    async submitForm() {
      this.isButtonLoading = true
      const url = `/students/classrooms/${this.classroomId}/class-contents/${this.contentId}/tasks/${this.taskId}/submissions/${this.submissionId}`

      const formData = new FormData()
      if (this.submissionType === 'text') {
        formData.append('answer', this.formData.text)
      } else if (this.submissionType === 'file') {
        formData.append('answer', this.filelist[0])
      } else if (this.submissionType === 'url') {
        formData.append('answer', this.formData.uploadLink)
      }

      await client.post(url, formData).then((response) => {
        this.isButtonLoading = false

        this.showToast(
          'success',
          'CheckIcon',
          'Berhasil',
          'Ujian berhasil dikumpulkan'
        )
        this.$router.push({
          name: checkAuthorizeRole('classroom-detail-exam'),
          params: {
            id: this.classroomId,
            contentId: this.contentId,
            taskId: this.taskId,
          },
        })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.drop-file {
  cursor: pointer;
  box-shadow: none !important;
  background-color: transparent !important;
  border: 2px dashed #d3d3d3 !important;
  border-radius: 0.428rem !important;
}
.card-material-type {
  cursor: pointer;
}

.remove-button-x {
  padding: 10px 10px;
  position: absolute;
  top: -10px;
  right: -10px;
  cursor: pointer;
}

.bg-drop-file {
  background-color: #c9ffe7 !important;
}
</style>
