<template>
  <div>
    <div v-if="!isLoading">
      <!-- File Submission -->
      <submission
        v-if="task.type === 'submission'"
        :tasks="task"
        :submission-type="task.content_type"
      />

      <!-- Multiple Choice Submission -->
      <quiz v-if="task.type === 'exam'" :content-data="task" />
    </div>

    <!-- Loading -->
    <div
      v-if="isLoading"
      class="d-flex flex-column align-items-center vertical-center"
    >
      <b-spinner variant="primary" />
      <span class="small-gap-top">Memuat Ujian</span>
    </div>
  </div>
</template>

<script>
// * Utils
import axios from 'axios'
// eslint-disable-next-line import/no-cycle
import { BSpinner } from 'bootstrap-vue'
import { avatarText, fileType } from '@core/utils/filter'
import { checkAuthorizeRole } from '@/auth/utils'

// * Components
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Submission from './component/Submission.vue'
import Quiz from './component/Quiz.vue'

export default {
  components: {
    BSpinner,

    Submission,
    Quiz,
  },
  data() {
    return {
      // State
      isLoading: true,

      // Default data
      classroomId: this.$router.currentRoute.params.id,
      contentId: this.$router.currentRoute.params.contentId,
      taskId: this.$router.currentRoute.params.taskId,

      // Classroom Data
      task: {},
      submissionType: '',
    }
  },
  beforeRouteEnter(to, from, next) {
    const { role, breadcrumb } = to.meta

    to.meta.breadcrumb[breadcrumb.length - 2].params = {
      id: to.params.id,
      contentId: to.params.contentId,
    }
    to.meta.breadcrumb[breadcrumb.length - 3].params = { id: to.params.id }
    next()
  },
  async mounted() {
    const baseUrl = `${process.env.VUE_APP_BASE_API_URL}`
    const url = `${baseUrl}/students/classrooms/${this.classroomId}/class-contents/${this.contentId}/tasks/${this.taskId}`

    const config = {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        'Content-Type': 'multipart/form-data',
      },
    }

    await axios
      .get(url, config)
      .then((response) => {
        const task = response.data.data.class_content

        if (task.type === 'exam') {
          task.task_content_type = task.exam_questions[0].type
        }
        if (task.type === 'submission') {
          task.content_type = task.submission_questions[0].type
        }
        this.task = task

        this.isLoading = false
      })
      .catch((error) => {
        this.isLoading = false
        console.error(error)
      })
  },
  methods: {
    avatarText,
    fileType,
    getExtension(filename) {
      return filename.split('.').pop()
    },
    ellipsis(text) {
      if (text.length > 6) {
        return `${text.substring(0, 10)}...${text.substr(
          text.lastIndexOf('.') + 1
        )}`
      }
      return text
    },
    onChange() {
      this.filelist = [...this.$refs.file.files]
    },
    remove(i) {
      this.filelist.splice(i, 1)
      const allDropFileContainer = document.querySelectorAll('.drop-file')

      allDropFileContainer.forEach((dropFile) => {
        dropFile.classList.remove('bg-drop-file')
      })
    },
    dragover(event) {
      event.preventDefault()
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains('bg-drop-file')) {
        event.currentTarget.classList.add('bg-drop-file')
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.remove('bg-drop-file')
    },
    drop(event) {
      event.preventDefault()
      const droppedFiles = event.dataTransfer.files

      if (droppedFiles.length > 1) {
        event.currentTarget.classList.remove('bg-drop-file')
        this.showToast(
          'danger',
          'XIcon',
          'Gagal',
          'File tidak boleh lebih dari satu'
        )
      } else {
        droppedFiles.forEach((element) => {
          const fileExtension = element.name.split('.').pop()
          const allowedExtension = [
            'pdf',
            'doc',
            'docx',
            'ppt',
            'pptx',
            'xls',
            'xlsx',
            'mp4',
            'mkv',
          ]

          if (allowedExtension.includes(fileExtension)) {
            this.filelist.push(element)
          } else {
            event.currentTarget.classList.remove('bg-drop-file')
            this.showToast('danger', 'XIcon', 'Gagal', 'File tidak didukung')
          }
        })
      }
    },
    showToast(variant, icon, title, message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text: message,
          variant,
        },
      })
    },
    validateForm() {
      let isValid = true
      if (this.formData.uploadLink === '' && this.filelist.length === 0) {
        this.formErrors.uploadLink = {
          state: false,
          message:
            'File atau Link Upload tidak boleh kosong (Pilih Salah Satu)',
        }

        isValid = false
      } else {
        this.formErrors.uploadLink = {
          state: null,
          message: '',
        }
      }

      if (!isValid) {
        this.showToast('danger', 'XIcon', 'Error', 'Form tidak valid')
        this.isLoading = false
      } else {
        this.isLoading = true
      }

      return isValid
    },
    async submitForm() {
      this.isLoading = true
      if (this.validateForm()) {
        // API basic configuration
        const baseUrl = `${process.env.VUE_APP_BASE_API_URL}`
        const url = `${baseUrl}/students/classrooms/${this.classroomId}/class-contents/${this.contentId}/tasks/${this.taskId}`
        const config = {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            'Content-Type': 'multipart/form-data',
          },
        }

        // Create form data
        const data = new FormData()
        data.append('class_task_id', this.taskId)
        data.append('classroom_id', this.classroomId)
        data.append('file', this.filelist[0])
        data.append('answer', this.formData.uploadLink)

        // create content material
        await axios
          .post(url, data, config)
          .then((response) => {
            // stop loading
            this.isLoading = false

            // show success toast
            this.showToast(
              'success',
              'CheckIcon',
              'Berhasil',
              'Ujian berhasil dikumpulkan'
            )

            // redirect to task
            this.$router.go(-1)
          })
          .catch((error) => {
            this.isLoading = false

            // show success toast
            this.showToast(
              'danger',
              'XIcon',
              'Gagal',
              error.response.data.message
            )
          })
      }
    },
  },
}
</script>

<style lang="scss">
.extra-small-gap-bottom {
  margin-bottom: 4px;
}

.regular-icon {
  top: 0 !important;
  font-size: 1.2rem;
}

.card-icon {
  top: 0 !important;
  font-size: 1.5rem;
}

.card-icon-lg {
  top: 0 !important;
  font-size: 2.5rem;
}

.regular-ul {
  padding-left: 1.5rem;
}

.round-button {
  padding: 0.2%;
  margin: 0.7%;
  font-size: 0.7rem;
  height: 2.5rem;
  width: 2.5rem;
}

.w-30 {
  width: 30%;
}

.exam-answer-options .form-control[readonly] {
  cursor: pointer;
  background-color: white !important;
}

.drop-file {
  cursor: pointer;
  box-shadow: none !important;
  background-color: transparent !important;
  border: 2px dashed #d3d3d3 !important;
  border-radius: 0.428rem !important;
}
.card-material-type {
  cursor: pointer;
}

.remove-button-x {
  padding: 10px 10px;
  position: absolute;
  top: -10px;
  right: -10px;
  cursor: pointer;
}

.bg-drop-file {
  background-color: #c9ffe7 !important;
}
</style>
